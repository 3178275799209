/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        


        // JavaScript to be fired on all pages

        // function myFunction() {
        //   var x = document.getElementById("myTopnav");
        //   if (x.className === "topnav") {
        //     x.className += " responsive";
        //   } else {
        //     x.className = "topnav";
        //   }
        // }

        var goTo = function(anchor) {
          $('html, body').animate({
              scrollTop: $(anchor).offset().top-0
          }, 1000);
        };

        $('a.go-to-top').on('click', function(e) {
          if($(this.hash).length && this.hash) {
            e.preventDefault();
            goTo(this.hash);
          }
        });


        // Megamenu colecciones
        $('.menu-equiponuevo').hover(function(){
          $('.megamenu-content').slideDown('slow');
        });

        $( ".megamenu-content" ).on( "mouseleave", function() {
          $('.megamenu-content').slideToggle('fast');
        });

        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          asNavFor: '.slider-nav'
        });

        $('.slider-nav').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          asNavFor: '.slider-for',
          dots: false,
          centerMode: true,
          focusOnSelect: true
        });

        Fancybox.bind('[data-fancybox]', {
          // Custom options for all galleries
        });    

        $('#menu-item-71 a.nav-link').attr('data-toggle', 'dropdown');
        //$('#menu-item-79 a.nav-link').attr('data-toggle', 'dropdown');    

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.owl-home').owlCarousel({
          loop:true,
          margin:10,
          nav:true,
          autoplay:true,
          autoplayTimeout:5000,
          responsive:{
              0:{
                items:1
              },
              600:{
                items:1
              },
              1000:{
                items:1
              }
            }
          });

          $('.owl-equipo-destacado').owlCarousel({
            loop:true,
            margin:10,
            nav:true,
            dots: false,
            responsive:{
                0:{
                  items:1
                },
                600:{
                  items:3
                },
                1000:{
                  items:4
                }
              }
            });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'refacciones_y_accesorios': {
      init: function() {

        $('.owl-refacciones').owlCarousel({
          loop:true,
          margin:10,
          nav:true,
          dots: false,
          responsive:{
              0:{
                items:1
              },
              600:{
                items:3
              },
              1000:{
                items:4
              }
            }
          });

      }
    },
    // About us page, note the change from about-us to about_us.
    'single': {
      init: function() {
        // JavaScript to be fired on the about us page

        $('.owl-segmentos-productos').owlCarousel({
          loop:true,
          margin:10,
          nav:false,
          dots:false,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:2
            },
            1000:{
              items:4
            }
          }
        });




      }
    },
    // About us page, note the change from about-us to about_us.
    'single_segmentos': {
      init: function() {
        // JavaScript to be fired on the about us page

        
        $('.owl-segmentos-productos').owlCarousel({
          loop:true,
          margin:10,
          nav:true,
          dots:false,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:2
            },
            1000:{
              items:4
            }
          }
        });

        $('.owl-segmentos-accion-imagen-videos').owlCarousel({
          loop:false,
          margin:10,
          nav:true,
          dots:false,
          responsive:{
            0:{
              items:1
            },
            600:{
              items:2
            },
            1000:{
              items:3
            }
          }
        });

        // $('.owl-segmentos-accion-todo').owlCarousel({
        //   loop:true,
        //   margin:10,
        //   nav:false,
        //   dots:true,
        //   responsive:{
        //     0:{
        //       items:1
        //     },
        //     600:{
        //       items:2
        //     },
        //     1000:{
        //       items:4
        //     }
        //   }
        // });

        // $('.owl-segmentos-accion-todo').owlCarousel({
        //   loop:true,
        //   margin:10,
        //   nav:false,
        //   dots:true,
        //   responsive:{
        //     0:{
        //       items:1
        //     },
        //     600:{
        //       items:2
        //     },
        //     1000:{
        //       items:4
        //     }
        //   }
        // });


        // JavaScript to be fired on the about us page
        $('.collapse').on('shown.bs.collapse', function() {
          $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");
        }).on('hidden.bs.collapse', function() {
          $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
        });


        Fancybox.bind('[data-fancybox="gallery-todos"]', {
          compact: false,
          contentClick: "iterateZoom",
          Images: {
            Panzoom: {
              maxScale: 2,
            },
          },
          Toolbar: {
            display: {
              left: [
                "infobar",
              ],
              middle : [],
              right: [
                "iterateZoom",
                "close",
              ],
            }
          }
        });   

        Fancybox.bind('[data-fancybox="gallery-images"]', {
          
        });   

        

        Fancybox.bind('[data-fancybox="video-gallery"]', {
          // Custom options
        });    


        document.getElementById('switch-decimal').addEventListener('click', function(e) {
          e.preventDefault();
          document.querySelectorAll('.item-ingles').forEach(function(element) {
            element.style.display = 'none';
          });
          document.querySelectorAll('.item-decimal').forEach(function(element) {
            element.style.display = 'inline';
          });
          document.getElementById('switch-decimal').classList.add('active');
          document.getElementById('switch-ingles').classList.remove('active');
        });
      
        document.getElementById('switch-ingles').addEventListener('click', function(e) {
          e.preventDefault();
          document.querySelectorAll('.item-decimal').forEach(function(element) {
            element.style.display = 'none';
          });
          document.querySelectorAll('.item-ingles').forEach(function(element) {
            element.style.display = 'inline';
          });
          document.getElementById('switch-ingles').classList.add('active');
          document.getElementById('switch-decimal').classList.remove('active');
        });
        

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
